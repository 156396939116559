import { EquipmentCommon } from '.';

class MessageCommon {
    public static readonly NOT_FOUND_EQUIPMENT_FILE = `Not found the "${EquipmentCommon.ROOT_FILE}" file`;
    public static readonly NOT_GET_EQUIPMENT_ID = `Can't get the EquipmentId inside "${EquipmentCommon.ROOT_FILE}" file`;
    public static readonly INVALID_FILE = `Invalid file`;
    public static readonly UPLOADED_SUCCESS_ALL_FILES = `Uploaded successful all files for Equipment: "{}"`;
    public static readonly UPLOADED_SUCCESS_SOME_FILES = `Uploaded successful some files for Equipment: "{}"`;

    public static readonly UPLOADED_ERROR_ALL_FILES = `Failed to upload all files for Equipment: "{}"`;
    public static readonly UPLOADED_THROW_ERROR = `Unexpected Uploading: {}`;
    public static readonly S3_ERROR = `AWS S3 Bucket: {}`;
    public static readonly MESSAGE_ERROR_ALREADY_BLOCKING_FLAG = `The upload is in progress somewhere else`;
}

export default MessageCommon;
