/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
    ICompletedScanningType,
    IFetchS3Type,
    INotifyType,
    ISetBucket,
    IThrowErrorUploadType,
    ITriggerErrorType,
    ITriggerLoadingType,
    IUploadType,
} from '../types';
import { AppAction, AuthenticatedPayload } from '../store';

const setNotifyType = (payload: INotifyType): AuthenticatedPayload => ({ type: AppAction.SET_NOTIFY, payload });

const setConfirmUploadType = (): AuthenticatedPayload => ({ type: AppAction.CONFIRM_UPLOAD });

const setCancelUploadType = (): AuthenticatedPayload => ({ type: AppAction.CANCEL_UPLOAD });

const setTriggerErrorType = (payload: ITriggerErrorType): AuthenticatedPayload => ({ type: AppAction.TRIGGER_ERROR, payload });

const setCompletedScanningType = (payload: ICompletedScanningType): AuthenticatedPayload => ({ type: AppAction.COMPLETED_FILES_SCANNING, payload });

const setTriggerLoadingType = (payload: ITriggerLoadingType): AuthenticatedPayload => ({ type: AppAction.TRIGGER_LOADING, payload });

const setFetchS3Type = (payload: IFetchS3Type): AuthenticatedPayload => ({ type: AppAction.FETCH_S3, payload });

const setCompletedUploadType = (payload: INotifyType): AuthenticatedPayload => ({ type: AppAction.COMPLETED_UPLOAD, payload });

const setClearErrorType = (): AuthenticatedPayload => ({ type: AppAction.CLEAR_ERROR });

const setUploadType = (payload: IUploadType) => ({ type: AppAction.SET_UPLOAD, payload });

const setThrowErrorUploadType = (payload: IThrowErrorUploadType) => ({ type: AppAction.THROW_ERROR_UPLOAD, payload });

const setRdsBucketType = (payload: ISetBucket) => ({ type: AppAction.SET_RDS_BUCKET, payload });

export {
    setNotifyType,
    setConfirmUploadType,
    setCancelUploadType,
    setTriggerErrorType,
    setTriggerLoadingType,
    setCompletedScanningType,
    setFetchS3Type,
    setCompletedUploadType,
    setClearErrorType,
    setUploadType,
    setThrowErrorUploadType,
    setRdsBucketType,
};
