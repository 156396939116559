/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Storage, StorageClass } from 'aws-amplify';
import { BaseCommon } from '../common';
import { BucketType } from '../types';

export class AmplifyS3Client<T> {
    private static _instance = new AmplifyS3Client<BucketType>(BaseCommon.ALL_S3_CONFIGS);
    private __storage: StorageClass = Storage;
    private __configs: AmplifyS3Config<T>[] = [];

    private constructor(__configs: AmplifyS3Config<T>[]) {
        if (__configs.length > 0) {
            this.__configs = __configs;
        }
    }

    public static async validateBucket(s3Config: AWSS3Config): Promise<ValidationResult> {
        try {
            Storage.configure({
                AWSS3: s3Config,
            });
            await Storage.list('', {
                ...customBucketRoot,
            });
            return {
                valid: true,
            };
        } catch (error: any) {
            console.error('ERROR::VALIDATE_BUCKET:: ', error);
            return {
                valid: false,
                errorMessage: error.message,
            };
        }
    }

    public static getInstance<T>(): AmplifyS3Client<T> {
        return this._instance as unknown as AmplifyS3Client<T>;
    }

    getInstance(name: T): AmplifyS3Client<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.name === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        Storage.configure({
            AWSS3: config.AWSS3,
        });
        this.__storage = Storage;
        return this;
    }

    getInstanceByBucketName(name: string): AmplifyS3Client<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.AWSS3.bucket === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        Storage.configure({
            AWSS3: config.AWSS3,
        });
        this.__storage = Storage;
        return this;
    }

    getConfigOfInstance(name: T): AmplifyS3Config<T> {
        const config = this.__configs.find((configuration: AmplifyS3Config<T>) => configuration.name === name);
        if (!config) {
            throw new Error(`Not found the "${name}" configuration`);
        }
        return config;
    }

    updateConfiguration(s3Config: AmplifyS3Config<T>): AmplifyS3Client<T> {
        this.__configs = [...this.__configs].map((instance: AmplifyS3Config<T>) => {
            if (instance.name !== s3Config.name) {
                return instance;
            }
            return {
                ...instance,
                AWSS3: {
                    ...instance.AWSS3,
                    ...s3Config.AWSS3,
                },
            };
        });
        return this;
    }

    async list(folder: string = '', config?: any): Promise<S3Response[]> {
        return await this.__storage.list(folder, {
            ...customBucketRoot,
            ...config,
        });
    }

    async put(key: string, object: any, config?: UploadCallback): Promise<any> {
        return await this.__storage.put(key, object, {
            resumable: false,
            ...customBucketRoot,
            ...config,
        });
    }

    async remove(key: string, config?: UploadCallback): Promise<any> {
        return await this.__storage.remove(key, {
            resumable: false,
            ...customBucketRoot,
            ...config,
        });
    }
}

export const customBucketRoot = {
    customPrefix: {
        public: '',
    },
};

export interface AmplifyS3Config<T> {
    AWSS3: AWSS3Config;
    name: T;
}

export interface AWSS3Config {
    bucket: string;
    region: string;
}

export interface S3Response {
    key: string;
    eTag: string;
    lastModified: string;
    size: number;
}

export interface UploadCallback {
    completeCallback?: (event: any) => void;
    progressCallback?: (progress: any) => void;
    errorCallback?: (error: any) => void;
    resumable?: boolean;
}

export interface ValidationResult {
    valid: boolean;
    errorMessage?: string;
}

const s3Client = AmplifyS3Client.getInstance<BucketType>();
export default s3Client;
