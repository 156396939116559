/* eslint-disable @typescript-eslint/no-inferrable-types */
const formatNumberWithCommas = (x: number | string): string => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const roundNumber = (num: number, decimal: number = 3): number => {
    return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};
const convertUnitFileSize = (byte: number | undefined | null): string => {
    const rootUnit = 1024;
    const nameOfUnits = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'BB', 'HB'];

    if (byte === null || byte === undefined) {
        return 0 + ` ${nameOfUnits[0]}`;
    }

    for (const [index, unit] of Object.entries(nameOfUnits)) {
        const i = Number(index);
        if (byte <= Math.pow(rootUnit, i + 1)) {
            return formatNumberWithCommas(roundNumber(byte / Math.pow(rootUnit, i))) + ` ${unit}`;
        }
    }
    return formatNumberWithCommas(byte) + ` ${nameOfUnits[0]}`;
};

const formatTime = (timer: number): string => {
    return timer < 10 ? `0${timer}` : timer.toString();
};

export { convertUnitFileSize, formatTime };
