import { LinearProgressProps, LinearProgressPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Box, LinearProgress as MuiLinearProgress, Typography } from 'nxg-ui-wrapper';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import styles from './LinearProgress.module.css';
import { BaseCommon } from '../../common';
interface LinearWithValueLabelProps {
    color?: OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit', LinearProgressPropsColorOverrides>;
    progress: number;
    skip?: boolean;
}

interface LinearProgressWithLabelProps extends LinearProgressProps {
    value: number;
    skip: boolean | string;
}

const LinearProgressWithLabel = (props: LinearProgressWithLabelProps): JSX.Element => {
    return (
        <Box className={styles.wrapper}>
            <Box className={styles.spacing}>
                <MuiLinearProgress variant="determinate" value={props.value} color={props.color} />
            </Box>
            <Box className={styles.text}>
                <Typography variant="body2" color="text.secondary">
                    {props.value === BaseCommon.PERCENT_COMPLETED && !props.skip ? <TaskAltIcon color="success" /> : `${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const LinearProgress = ({ color, progress, skip = true }: LinearWithValueLabelProps): JSX.Element => {
    return (
        <Box className={styles.text}>
            <LinearProgressWithLabel value={progress} color={color} skip={skip} />
        </Box>
    );
};

export default LinearProgress;
