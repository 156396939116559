import { Typography } from 'nxg-ui-wrapper';
import { EquipmentCommon, TextCommon } from '../common';

import styles from './PickerTitle.module.css';

const PickerTitle = (): JSX.Element => {
    return (
        <Typography component="div" className={styles.wrapper}>
            <Typography className={styles['blue-text']} component="span">
                {TextCommon.SELECT}
            </Typography>{' '}
            {TextCommon.OR}{' '}
            <Typography className={styles['blue-text']} component="span">
                {TextCommon.DRAG}&amp;{TextCommon.DROP}
            </Typography>
            <Typography component="p">
                {TextCommon.THE}{' '}
                <Typography className={styles['red-text']} component="span">
                    {TextCommon.LOG_FOLDER}
                </Typography>{' '}
                {TextCommon.WHICH_CONTAINS}{' '}
                <Typography className={styles['red-text']} component="span">
                    {EquipmentCommon.ROOT_FILE}
                </Typography>
            </Typography>
        </Typography>
    );
};

export default PickerTitle;
