/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-expressions */
import { useCallback, useState } from 'react';
import { Grid, Stack } from 'nxg-ui-wrapper';
import { useDropzone } from 'react-dropzone';
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise';

import globalStyles from '../../global/style.module.css';
import styles from './LogPicker.module.css';
import { filterFileAndFolder, handleDirectoryEntry, validationFile } from '../../utils';
import { FileDb3 } from '../../types';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        directory?: string;
        webkitdirectory?: string;
    }
}
export interface LogPickerProps {
    disabled?: boolean;
    filterFolder?: string[];
    filterFileExtensions?: string[];
    onLoading: (value: boolean) => void;
    onLoadedFiles: (files: FileDb3[]) => void;
    onError: (error: any) => void;
    children: JSX.Element;
}

const LogPicker = ({ disabled, filterFolder, filterFileExtensions, onLoading, onLoadedFiles, onError, children }: LogPickerProps): JSX.Element => {
    const [isOpenPicker, setIsOpenPicker] = useState(false);

    const onDropzoneError = useCallback(async (error: Error) => {
        onError(error);
    }, []);

    useDropzone({
        onError: onDropzoneError,
        noClick: true,
        noKeyboard: true,
    });

    const handleClick = async (): Promise<void> => {
        try {
            if (disabled || isOpenPicker) {
                return;
            }
            let fileSystemDirectoryHandle;
            try {
                setIsOpenPicker(true);
                fileSystemDirectoryHandle = await window.showDirectoryPicker({ mode: 'read' });
            } catch (error) {
                return;
            }
            onLoading(true);
            await validationFile(fileSystemDirectoryHandle);

            // scan deep all folders
            const outputs: FileDb3[] = [];
            await handleDirectoryEntry(fileSystemDirectoryHandle, outputs, ['/', fileSystemDirectoryHandle.name].join(''));
            const data = await filterFileAndFolder(outputs, filterFolder, filterFileExtensions);
            onLoadedFiles(data);
        } catch (error) {
            onError(error);
        } finally {
            setIsOpenPicker(false);
        }
    };

    const handleDrop = async (evt: any) => {
        try {
            if (disabled || evt.dataTransfer.items.length !== 1) {
                return;
            }
            evt.preventDefault();
            onLoading(true);

            const files = await getFilesFromDataTransferItems(evt.dataTransfer.items);
            const outputs: FileDb3[] = [...files].map((file: any) => {
                file.path = `/${file.filepath}`;
                return file as FileDb3;
            });
            const data = await filterFileAndFolder(outputs, filterFolder, filterFileExtensions);
            onLoadedFiles(data);
        } catch (error) {
            onError(error);
        }
    };

    const bgStyle = disabled ? 'bg-no-drop' : 'bg-pointer';
    const cursorStyle = disabled ? 'cursor-no-drop' : 'cursor-pointer';

    return (
        <Grid onDrop={handleDrop} className={styles['none-select']} onClick={handleClick}>
            <Stack className={`${globalStyles[cursorStyle]} ${globalStyles[bgStyle]} ${styles['log-picker']}`}>{children}</Stack>
        </Grid>
    );
};

export default LogPicker;
