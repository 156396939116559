import { BucketType, EnvironmentMode } from '../types';
import { AmplifyS3Config } from '../custom/amplifys3.client';
import { buildAmplifyS3Config } from '../utils/config.util';

export const MODE = EnvironmentMode.REL;

export const isDevelopMode = (): boolean => {
    return MODE.toString() === EnvironmentMode.DEV.toString();
};

const MODE_DEV_PREFIX = 'm-dev-';
const BE_REGION = 'ap-southeast-1';
const S3_RDS_BUCKET = 'aims-rds';
const S3_TIMESTREAM_BUCKET = 'aims-timestream';
const S3_BLOCKING_BUCKET = 'aims-client-blocking';

export const PERCENT_COMPLETED = 100;

export const APP_SCANNING_DELAY_TIME = 500;
export const APP_INTERVAL_TIME = 10;
export const APP_RDS_FOLDER = ['TraceLog', 'WaferLog', 'AlarmLog'];
export const APP_TIMESTREAM_FOLDER = ['SvLog'];
export const APP_ACCEPT_EXTENSION_FILES = ['.db3', '.xml'];
export const FILENAME_BLOCKING_FLAG = 'blocking-ui.flag';

const S3_RDS_BUCKET_DEV_MODE = MODE_DEV_PREFIX + S3_RDS_BUCKET;
const S3_TIMESTREAM_BUCKET_DEV_MODE = MODE_DEV_PREFIX + S3_TIMESTREAM_BUCKET;
const S3_BLOCKING_BUCKET_DEV_MODE = MODE_DEV_PREFIX + S3_BLOCKING_BUCKET;

export const S3_REGION = isDevelopMode() ? BE_REGION : '';
export const APP_RDS_BUCKET = isDevelopMode() ? S3_RDS_BUCKET_DEV_MODE : S3_RDS_BUCKET;
export const APP_TS_BUCKET = isDevelopMode() ? S3_TIMESTREAM_BUCKET_DEV_MODE : S3_TIMESTREAM_BUCKET;
export const APP_BLOCKING_BUCKET = isDevelopMode() ? S3_BLOCKING_BUCKET_DEV_MODE : S3_BLOCKING_BUCKET;

export const APP_RDS_CONFIG: AmplifyS3Config<BucketType> = buildAmplifyS3Config(BucketType.RDS, APP_RDS_BUCKET, BE_REGION);
export const APP_TS_CONFIG: AmplifyS3Config<BucketType> = buildAmplifyS3Config(BucketType.TIMESTREAM, APP_TS_BUCKET, BE_REGION);
export const APP_BLOCKING_CONFIG: AmplifyS3Config<BucketType> = buildAmplifyS3Config(BucketType.BLOCKING, APP_BLOCKING_BUCKET, BE_REGION);

export const ALL_S3_CONFIGS = [APP_RDS_CONFIG, APP_TS_CONFIG, APP_BLOCKING_CONFIG];
export const ALL_S3_BUCKETS = [S3_RDS_BUCKET, S3_TIMESTREAM_BUCKET, S3_BLOCKING_BUCKET];
