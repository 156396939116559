import { Dispatch } from 'react';

import { FileOfBucket, S3StatusBucket, S3StatusObjectFile, UploadObjectFile } from '../components';
import { BrowserStorageKey, BucketType, FileDb3, IThrowErrorUploadType, IUploadType } from '../types';
import { AuthenticatedPayload } from '../store';
import { TypeCommon, BaseCommon, EquipmentCommon } from '../common';
import { AmplifyS3Config, AWSS3Config } from '../custom/amplifys3.client';
import { BrowserStorageUtil, ConfigurationUtil } from '../utils';
import s3Client from '../custom/amplifys3.client';

const fetchEquipmentLogFromS3 = async (): Promise<S3StatusBucket[]> => {
    const rds = await fetchSingleDataFromS3(BucketType.RDS);
    const timestream = await fetchSingleDataFromS3(BucketType.TIMESTREAM);
    return [rds, timestream];
};

const fetchBlockingFromS3 = async (): Promise<S3StatusBucket> => {
    return await fetchSingleDataFromS3(BucketType.BLOCKING);
};

const fetchSingleDataFromS3 = async (type: BucketType): Promise<S3StatusBucket> => {
    const objects = await (s3Client.getInstance(type).list() as unknown as Promise<S3StatusObjectFile[]>);
    const bucketName = s3Client.getConfigOfInstance(type)?.AWSS3.bucket;
    return transformToS3StatusBucket(objects, bucketName);
};

const updateBlockingFlag = async (value: boolean): Promise<void> => {
    try {
        if (value === true) {
            await s3Client.getInstance(BucketType.BLOCKING).put(BaseCommon.FILENAME_BLOCKING_FLAG, BaseCommon.FILENAME_BLOCKING_FLAG, {
                errorCallback: (error: any) => {
                    throw error;
                },
            });
        } else {
            await s3Client.getInstance(BucketType.BLOCKING).remove(BaseCommon.FILENAME_BLOCKING_FLAG);
        }
    } catch (error: any) {
        console.error('BLOCKING_FLAG::ERROR::', error);
        throw error;
    }
};

const transformToS3StatusBucket = (data: S3StatusObjectFile[], name: string | undefined): S3StatusBucket => {
    const objects = data
        .map((item: S3StatusObjectFile) => {
            const split = item.key.split('/');
            item.equipmentId = split[0];
            item.key = split[1];
            return item;
        })
        .filter((item: S3StatusObjectFile) => item.key && item.key.trim());

    const totalSize = data.reduce((total: number, item: S3StatusObjectFile) => total + item.size, 0);
    return {
        name,
        totalFile: objects.length,
        totalSize,
        objects,
    };
};

const buildResourceEntry = (lcFiles: FileDb3[]): FileOfBucket[] => {
    const [rds, timestream] = lcFiles.reduce((result: UploadObjectFile[][], file: FileDb3) => transformFileToResource(result, file), [[], []]);
    const rdsBucketName = s3Client.getConfigOfInstance(BucketType.RDS).AWSS3.bucket;
    const tsBucketName = s3Client.getConfigOfInstance(BucketType.TIMESTREAM).AWSS3.bucket;
    const rdsResources: FileOfBucket = buildResourceForUpload(rdsBucketName, rds);
    const timestreamResources: FileOfBucket = buildResourceForUpload(tsBucketName, timestream);
    return [rdsResources, timestreamResources];
};

const transformFileToResource = (result: UploadObjectFile[][], file: FileDb3): UploadObjectFile[][] => {
    const folder = file.path?.split(file.name)[0].split('/').slice(-2, -1).join('/') || '';
    const isMatchDb3File = file.name.match(EquipmentCommon.REGEX_DB3_FILE);
    if (!isMatchDb3File) {
        return result;
    }

    const isStartWithNameOfFolder = file.name.startsWith(folder);

    if (BaseCommon.APP_RDS_FOLDER.includes(folder) && isStartWithNameOfFolder) {
        const indexS3 = 0;
        result[indexS3].push({
            isError: false,
            size: file.size,
            filename: file.name,
            lastModified: file.lastModified,
            progress: 0,
            indexS3,
            folder,
            file,
        });
        return result;
    }
    if (BaseCommon.APP_TIMESTREAM_FOLDER.includes(folder) && isStartWithNameOfFolder) {
        const indexS3 = 1;
        result[indexS3].push({
            isError: false,
            size: file.size,
            filename: file.name,
            lastModified: file.lastModified,
            progress: 0,
            indexS3,
            folder,
            file,
        });
        return result;
    }
    return result;
};

const buildResourceForUpload = (s3Provider: string, objects: UploadObjectFile[]): FileOfBucket => {
    const totalSize = objects.reduce((total: number, item: UploadObjectFile) => total + item.size, 0);
    return {
        name: s3Provider,
        isError: false,
        progress: 0,
        totalFile: objects.length,
        objects,
        totalSize,
    };
};

const calculateUploadingProgress = async (
    uploadingFiles: FileOfBucket[],
    equipmentId: string,
    dispatch: Dispatch<AuthenticatedPayload>,
): Promise<void> => {
    for await (const bucket of uploadingFiles) {
        for await (const object of bucket.objects) {
            try {
                await s3Client.getInstanceByBucketName(bucket.name as string).put([equipmentId, object.filename].join('/'), object.file, {
                    progressCallback: (progress: any) => handleProgressCallback(uploadingFiles, object, progress, dispatch),
                    errorCallback: handleErrorCallback,
                });
            } catch (err: any) {
                const uploaded = handleProgressError(uploadingFiles, object);
                const payload: IThrowErrorUploadType = {
                    uploadingFiles: [...uploaded],
                };
                dispatch(TypeCommon.setThrowErrorUploadType(payload));
            }
        }
    }
};

const handleErrorCallback = (err: any): void => {
    throw err;
};

const handleProgressError = (upload: FileOfBucket[], object: UploadObjectFile): FileOfBucket[] => {
    object.isError = true;
    object.file = null;
    upload[object.indexS3].isError = true;
    const newKb = object.size - (object.progress * object.size) / BaseCommon.PERCENT_COMPLETED;
    object.progress = BaseCommon.PERCENT_COMPLETED;
    upload = calculateProgressForBucket(object, upload, newKb);
    return upload;
};

const handleProgressCallback = (upload: FileOfBucket[], object: UploadObjectFile, progress: any, dispatch: Dispatch<AuthenticatedPayload>): void => {
    const total = progress.total;
    if (total === 0) {
        return;
    }
    const oldProgress = object.progress;
    const newProgress = (progress.loaded / total) * BaseCommon.PERCENT_COMPLETED;

    if (newProgress < 99 && Math.trunc(newProgress) === Math.trunc(object.progress)) {
        return;
    }
    object.progress = newProgress;

    // Clear file if uploaded successful
    if (object.progress === BaseCommon.PERCENT_COMPLETED) {
        object.file = null;
    }

    const newKb = progress.loaded - (oldProgress * progress.total) / BaseCommon.PERCENT_COMPLETED;

    // calculate and update the progress for bucket
    upload = calculateProgressForBucket(object, upload, newKb);
    const payload: IUploadType = {
        uploadingFiles: [...upload],
    };
    dispatch(TypeCommon.setUploadType(payload));
};

const isCompletedProgress = (uploadingFiles: FileOfBucket[]): boolean => {
    const hasTwoBuckets = uploadingFiles && uploadingFiles.length === 2;
    if (!hasTwoBuckets || isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isCompletedUploaded(uploadingFiles);
};

const isCompletedUploaded = (uploadingFiles: FileOfBucket[]): boolean => {
    const totalProgress = uploadingFiles.reduce((total, file: FileOfBucket) => {
        let result = total + BaseCommon.PERCENT_COMPLETED;
        if (file.objects.length !== 0) {
            const eachProgress = file.objects.reduce((_total: number, uploadFile: UploadObjectFile) => {
                return _total + uploadFile.progress;
            }, 0);
            const totalSubProgress = eachProgress / file.objects.length;
            result = total + totalSubProgress;
        }
        return result;
    }, 0);
    const finalProgress = totalProgress / uploadingFiles.length;
    return finalProgress === BaseCommon.PERCENT_COMPLETED;
};

const isEmptyLocalFiles = (uploadingFiles: FileOfBucket[]): boolean => {
    return uploadingFiles.every((value: FileOfBucket) => value.objects.length === 0);
};

const calculateProgressForBucket = (object: UploadObjectFile, upload: FileOfBucket[], newKb: number): FileOfBucket[] => {
    const indexS3Bucket = object.indexS3;
    const progressBucket = upload[indexS3Bucket];
    const uploadedProgress = progressBucket.progress + (newKb / progressBucket.totalSize) * BaseCommon.PERCENT_COMPLETED;

    upload[indexS3Bucket] = {
        ...progressBucket,
        progress: Math.ceil(uploadedProgress) > BaseCommon.PERCENT_COMPLETED ? BaseCommon.PERCENT_COMPLETED : uploadedProgress,
    };

    return upload;
};

const isErrorAnyFileUploaded = (uploadingFiles: FileOfBucket[]): boolean => {
    const hasTwoBuckets = uploadingFiles && uploadingFiles.length === 2;
    if (!hasTwoBuckets || isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isAnyFileError(uploadingFiles);
};

const isErrorAllFilesUploaded = (uploadingFiles: FileOfBucket[]): boolean => {
    const hasTwoBuckets = uploadingFiles && uploadingFiles.length === 2;
    if (!hasTwoBuckets || isEmptyLocalFiles(uploadingFiles)) {
        return false;
    }
    return isAllFilesError(uploadingFiles);
};

const isAllFilesError = (uploadingFiles: FileOfBucket[]): boolean => {
    return uploadingFiles
        .filter((file: FileOfBucket) => {
            return file.objects.length !== 0;
        })
        .every((file: FileOfBucket) => {
            if (!file.isError) {
                return false;
            }
            return file.objects.every((subFile: UploadObjectFile) => {
                return subFile.isError;
            });
        });
};

const isAnyFileError = (uploadingFiles: FileOfBucket[]): boolean => {
    return uploadingFiles
        .filter((file: FileOfBucket) => {
            return file.objects.length !== 0;
        })
        .some((file: FileOfBucket) => file.isError);
};

const getBucketAndRegion = (url: string): AWSS3Config => {
    let bucket = '';
    let region = '';
    try {
        const urlParser = new URL(url);
        const pathSplit = urlParser.pathname.split('/');
        bucket = pathSplit[pathSplit.length - 1];
        region = urlParser.searchParams.get('region') as string;
    } catch (error) {
        console.error('ERROR:getBucketAndRegion:: ', error);
    }
    return ConfigurationUtil.buildS3Config(bucket, region);
};

const retrieveConfigFromSession = (type: BucketType): AmplifyS3Config<BucketType> | null => {
    const region = BrowserStorageUtil.retrieveFromSession<string>(BrowserStorageKey.S3_REGION);

    switch (type) {
        case BucketType.RDS:
            const bucketName = BrowserStorageUtil.retrieveFromSession<string>(BrowserStorageKey.S3_BUCKET_RDS);
            if (!bucketName || !region) {
                return null;
            }
            console.log('RetrieveConfigFromSession:: ', { bucketName, region });
            return ConfigurationUtil.buildAmplifyS3Config(type, bucketName, region);

        default:
            return null;
    }
};

const storeConfigToSession = (type: BucketType, bucket: string, region: string): void => {
    BrowserStorageUtil.storeToSession(BrowserStorageKey.S3_REGION, region);

    switch (type) {
        case BucketType.RDS:
            BrowserStorageUtil.storeToSession(BrowserStorageKey.S3_BUCKET_RDS, bucket);
            break;
        default:
            break;
    }
};

const initialConfigToSession = (config: AmplifyS3Config<BucketType>): AmplifyS3Config<BucketType> => {
    const configIntoSession = retrieveConfigFromSession(config.name);

    /* For "MODE.REL", need to call RestApi to get Region
        === To Do call RestAPI ===
    */

    switch (config.name) {
        case BucketType.RDS:
            if (configIntoSession === null) {
                storeConfigToSession(config.name, config.AWSS3.bucket, config.AWSS3.region);
            }
            break;
        default:
            break;
    }
    return retrieveConfigFromSession(config.name) || config;
};

export {
    buildResourceEntry,
    fetchEquipmentLogFromS3,
    fetchBlockingFromS3,
    calculateUploadingProgress,
    isCompletedProgress,
    isEmptyLocalFiles,
    isErrorAllFilesUploaded,
    isErrorAnyFileUploaded,
    getBucketAndRegion,
    retrieveConfigFromSession,
    storeConfigToSession,
    initialConfigToSession,
    updateBlockingFlag,
};
