class TextCommon {
    public static readonly SIGN_IN_HEADER_TITLE = `Sign-In Information`;

    public static readonly APP_TITLE = `AIMs Data Importer`;
    public static readonly SCANNING = `Scanning...`;
    public static readonly NO = `No`;
    public static readonly BUCKET = `Bucket`;
    public static readonly TOTAL_FILE = `Total Files`;
    public static readonly TOTAL_SIZE = `Total Size`;
    public static readonly EQUIPMENT = `Equipment`;
    public static readonly FILENAME = `File Name`;
    public static readonly SIZE = `Size`;
    public static readonly DATE = `Date`;
    public static readonly FOLDER_NAME = `Folder name`;
    public static readonly PROGRESS = `Progress`;
    public static readonly SIGN_OUT = `Sign Out`;
    public static readonly AGREE = `Agree`;
    public static readonly DISAGREE = `Disagree`;
    public static readonly CLOSE = `Close`;
    public static readonly CANCEL = `Cancel`;
    public static readonly UPLOAD_FILES = `Upload Files`;
    public static readonly DROP = `Drop`;
    public static readonly DRAG = `Drag`;
    public static readonly SELECT = `Select`;
    public static readonly OR = `or`;
    public static readonly WHICH_CONTAINS = `which contains the`;
    public static readonly THE = `the`;
    public static readonly LOG_FOLDER = `LOG FOLDER`;
    public static readonly COPYRIGHT = `Copyright`;
    public static readonly COMPANY = `TOKYO SEIMITSU CO., LTD`;
    public static readonly ACCRETECH = `Accretech`;
    public static readonly AUTO_REFRESH = `Auto refresh after`;
    public static readonly SECONDS = `second(s)`;
    public static readonly ALERT = `alert`;
    public static readonly FORCE_REFRESH = `Force Refresh`;
    public static readonly INPUT_BUCKET_URL = `Input the Bucket URL`;

    public static readonly S3_TABLE_HEADER = `AWS S3 Status`;
    public static readonly S3_TABLE_SUB_HEADER = `Log Files`;

    public static readonly UPLOADING_WARNING = `Files will start uploading to S3 and the Application will have disabled functionality.`;
    public static readonly UPLOADING_QUESTION = `Do you want to upload files to S3?`;
    public static readonly UPLOADING_TITLE = `Equipment's Logs Information`;
    public static readonly UPLOADING_TABLE_TITLE = `Files Upload Progressing`;
    public static readonly UPLOADING_TABLE_SUB_TITLE = `Files`;
}

export default TextCommon;
