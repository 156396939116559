import { ChangeEvent, memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from 'nxg-ui-wrapper';
import CachedIcon from '@mui/icons-material/Cached';

import styles from './S3StatusGrid.module.css';
import NoData from '../NoData';
import S3StatusRow, { S3StatusBucket } from './S3StatusRow';
import { TextCommon } from '../../common';
import Button from '../Button';
import { formatTime } from '../../utils';

export interface S3StatusGridProp {
    columns: string[];
    nestedColumns: string[];
    data: S3StatusBucket[];
    auto: boolean;
    timer: number;
    onRefresh: () => Promise<void>;
    onChangeAuto: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const S3StatusGrid = ({ columns, nestedColumns, data, auto, timer, onRefresh, onChangeAuto }: S3StatusGridProp): JSX.Element => {
    const isEmptyData = useMemo(() => {
        const [rdsArr, timestreamArr] = data;
        return rdsArr?.objects.length === 0 && timestreamArr?.objects.length === 0;
    }, [data]);

    return (
        <>
            <Grid className={styles.wrapper}>
                <Grid item className={styles.item} xs={12} md={6}>
                    <Typography variant="h6" marginRight={1}>
                        {TextCommon.S3_TABLE_HEADER}
                    </Typography>
                    <Button variant="contained" startIcon={<CachedIcon className={auto ? styles['rotate-icon'] : ''} />} onClick={onRefresh}>
                        {TextCommon.FORCE_REFRESH}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} className={styles.item} justifyContent="end">
                    <FormControlLabel control={<Switch color="primary" checked={auto} onChange={onChangeAuto} />} label="" labelPlacement="bottom" />
                    <Typography component="p" className={styles.refresh}>
                        {TextCommon.AUTO_REFRESH}{' '}
                        <Typography component="span" className={styles['text-special']}>
                            {formatTime(timer)}
                        </Typography>{' '}
                        {TextCommon.SECONDS}
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" />
            <Grid padding={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item: string, index: number) => (
                                    <StyledTableCell key={index}>{item} </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isEmptyData && (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!isEmptyData &&
                                data
                                    .filter((item: S3StatusBucket) => item.objects.length)
                                    .map((item: S3StatusBucket, index: number) => (
                                        <S3StatusRow key={index} columns={nestedColumns} bucket={item} header={TextCommon.S3_TABLE_SUB_HEADER} />
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};

export default memo(S3StatusGrid);
export * from './S3StatusRow';
