import { BrowserStorageKey } from '../types';

class BrowserStorage {
    public static readonly SESSION = sessionStorage;
    public static readonly LOCAL = localStorage;
}

export enum BrowserStorageType {
    SESSION,
    LOCAL,
}

export class BrowserStorageUtil {
    public static getInstance(instanceType: BrowserStorageType): Storage {
        if (instanceType === BrowserStorageType.LOCAL) {
            return BrowserStorage.LOCAL;
        }
        return BrowserStorage.SESSION;
    }

    public static store<T>(instanceType: BrowserStorageType, key: string, value: T): void {
        const storage = BrowserStorageUtil.getInstance(instanceType);
        storage.setItem(key, JSON.stringify(value));
    }

    public static retrieve<T>(instanceType: BrowserStorageType, key: string): T | null {
        const storage = BrowserStorageUtil.getInstance(instanceType);
        const data = storage.getItem(key) as string;
        try {
            return JSON.parse(data) as T;
        } catch (error) {
            return null;
        }
    }

    public static cleanUp(instanceType: BrowserStorageType): void {
        const storage = BrowserStorageUtil.getInstance(instanceType);
        storage.clear();
    }

    public static storeToSession<O>(key: BrowserStorageKey, value: O): void {
        BrowserStorageUtil.store<O>(BrowserStorageType.SESSION, key, value);
    }

    public static retrieveFromSession<O>(key: BrowserStorageKey): O | null {
        return BrowserStorageUtil.retrieve<O>(BrowserStorageType.SESSION, key) || null;
    }
}
