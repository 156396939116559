import { memo, useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from 'nxg-ui-wrapper';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import UploadStatusGrid, { FileOfBucket } from '../UploadStatusGrid';
import { TextCommon } from '../../common';
import Button from '../Button';
import Confirmation from '../Confirmation';
import styles from './UploadingDialog.module.css';
import globalStyles from '../../global/style.module.css';

export interface UploadingDialogProps {
    open: boolean;
    data: FileOfBucket[];
    isUploading: boolean;
    isUploaded: boolean;
    title: string;
    errorReason: string;
    Transition?: any;
    onClose: (event?: object, reason?: string) => void;
    onConfirm: () => Promise<void>;
}

const UploadingDialog = ({
    data,
    open,
    title,
    errorReason,
    onClose,
    onConfirm,
    Transition,
    isUploading,
    isUploaded,
}: UploadingDialogProps): JSX.Element => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
        setOpenConfirmation(previousOpen => !previousOpen);
    };

    const handleAgree = useCallback((): void => {
        setOpenConfirmation(false);
        onConfirm();
    }, [openConfirmation, anchorEl]);

    const handleDisagree = useCallback((): void => {
        setOpenConfirmation(false);
    }, []);

    const handleClose = useCallback((): void => {
        setOpenConfirmation(false);
        onClose();
    }, [openConfirmation, anchorEl]);

    return (
        <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
            <DialogTitle className={styles.header}>
                <Typography variant="h5" component="span" className={globalStyles.f600}>
                    {TextCommon.UPLOADING_TITLE}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container className={styles['wrapper-content']}>
                    <Typography variant="h4" component="span" className={styles.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid>
                    <Grid item bgcolor="#fff" borderRadius={2}>
                        <UploadStatusGrid
                            columns={['', TextCommon.BUCKET, TextCommon.TOTAL_FILE, TextCommon.TOTAL_SIZE, TextCommon.PROGRESS]}
                            nestedColumns={[TextCommon.NO, TextCommon.FILENAME, TextCommon.FOLDER_NAME, TextCommon.SIZE, TextCommon.PROGRESS]}
                            errorReason={errorReason}
                            data={data}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            {!isUploading && !isUploaded && (
                <DialogActions className={styles.action}>
                    <Button onClick={handleClick} variant="contained" startIcon={<CloudUploadIcon />}>
                        {TextCommon.UPLOAD_FILES}
                    </Button>

                    <Button variant="outlined" startIcon={<CancelIcon />} onClick={handleClose}>
                        {TextCommon.CANCEL}
                    </Button>
                </DialogActions>
            )}
            {isUploaded && (
                <DialogActions className={styles.action}>
                    <Button variant="contained" startIcon={<HighlightOffIcon />} onClick={handleClose}>
                        {TextCommon.CLOSE}
                    </Button>
                </DialogActions>
            )}
            {openConfirmation && (
                <Confirmation
                    title={TextCommon.UPLOADING_QUESTION}
                    content={TextCommon.UPLOADING_WARNING}
                    open={openConfirmation}
                    anchorEl={anchorEl}
                    onAgree={handleAgree}
                    onDisagree={handleDisagree}
                />
            )}
        </Dialog>
    );
};

export default memo(UploadingDialog);
