import { Box } from 'nxg-ui-wrapper';
import { TextCommon } from '../../common';

import styles from './Footer.module.css';

const Footer = (): JSX.Element => {
    return (
        <Box className={styles.wrapper}>
            {TextCommon.COPYRIGHT} © {new Date().getFullYear()} - {TextCommon.COMPANY}.
        </Box>
    );
};

export default Footer;
