import { useMemo } from 'react';
import { Typography, Grid, IconButton } from 'nxg-ui-wrapper';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';

import styles from './Header.module.css';
import { LOGO } from '../../assets';
import { TextCommon } from '../../common';
import Button from '../Button';
import { TypeContext } from '../../store';
import { useAuthenticated } from '../../hooks/context';
import { S3StatusBucket } from '../S3StatusGrid';

export interface HeaderProps {
    onSignOut: () => void;
    onOpenBackdoor: () => void;
    onForceRemoveBlocking: () => void;
}

const Header = ({ onSignOut, onOpenBackdoor, onForceRemoveBlocking }: HeaderProps): JSX.Element => {
    const [appState]: TypeContext = useAuthenticated();

    const handleSignOut = (): void => {
        onSignOut();
    };

    const handleBackdoorClick = (): void => {
        onOpenBackdoor();
    };

    const handleForceRemove = (): void => {
        onForceRemoveBlocking();
    };

    const renderForceRemoveBlocking = (): JSX.Element => {
        const isExistedFileInS3: boolean = useMemo(() => {
            return appState.s3Files.find((f: S3StatusBucket) => f.totalSize > 0) !== undefined;
        }, [appState.s3Files]);

        if (appState.isOtherProcessing && !isExistedFileInS3) {
            return (
                <IconButton color="error" onClick={handleForceRemove} sx={{ mr: 1 }}>
                    <DeleteSweepOutlinedIcon />
                </IconButton>
            );
        }

        return <></>;
    };

    const renderByMode = (): JSX.Element => {
        return (
            <Typography sx={{ ml: 1 }}>
                TOKY
                <Typography style={{ cursor: 'pointer' }} component="span" onClick={handleBackdoorClick}>
                    O
                </Typography>{' '}
                SEIMITSU
            </Typography>
        );
    };

    return (
        <Grid className={styles.fluid} sx={{ boxShadow: 1 }}>
            <Grid className={styles.wrapper}>
                <Grid container className={styles.wrapper__container}>
                    <Grid flexGrow={1}>
                        <Grid container alignItems={'center'}>
                            <img height="36" src={LOGO} alt={TextCommon.ACCRETECH} />
                            {renderByMode()}
                        </Grid>
                    </Grid>
                    <Grid>
                        {renderForceRemoveBlocking()}
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={handleSignOut} disabled={appState.isUploading}>
                            {TextCommon.SIGN_OUT}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;
