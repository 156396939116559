import { Dispatch, useReducer } from 'react';

import AuthenticatedContext from './context';
import reducer, { AuthenticatedPayload, AuthenticatedState, initialState } from './reducer';

export interface AuthenticatedProps {
    children: any;
}

export type TypeContext = [AuthenticatedState, Dispatch<AuthenticatedPayload>];

const AuthenticatedProvider = ({ children }: AuthenticatedProps): JSX.Element => {
    const [state, dispatch]: TypeContext = useReducer(reducer, initialState);

    return <AuthenticatedContext.Provider value={[state, dispatch]}>{children}</AuthenticatedContext.Provider>;
};

export default AuthenticatedProvider;
