import { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from 'nxg-ui-wrapper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { convertUnitFileSize } from '../../utils';
import { S3Response } from '../../custom/amplifys3.client';

export interface S3StatusObjectFile extends S3Response {
    equipmentId: string;
}

export interface S3StatusBucket {
    name: string | undefined;
    totalFile: number;
    totalSize: number;
    objects: S3StatusObjectFile[];
}
export interface S3StatusRowProp {
    columns: string[];
    bucket: S3StatusBucket;
    header: string;
}

const CustomizedCell = styled(TableCell)(
    ({ theme }) => `
    color: ${theme.palette.primary.main};
    fontWeight: ${800};
`,
);

const S3StatusRow = ({ bucket, header, columns }: S3StatusRowProp): JSX.Element => {
    const [open, setOpen] = useState(true);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, bgcolor: theme => theme.palette.grey[200] }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={(): void => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <CustomizedCell component="th" scope="row">
                    <Chip label={bucket.name} color="primary" variant="outlined" />
                </CustomizedCell>
                <CustomizedCell>
                    <Chip label={bucket.totalFile} color="success" />
                </CustomizedCell>
                <CustomizedCell>
                    <Chip label={'~ ' + convertUnitFileSize(bucket.totalSize)} color="error" variant="filled" />
                </CustomizedCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography gutterBottom component="div" fontWeight={600}>
                                {header}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item: string, index: number) => (
                                            <TableCell key={index}>{item} </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bucket.objects.map((object: S3StatusObjectFile, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {object.equipmentId}
                                            </TableCell>
                                            <TableCell>{object.key}</TableCell>
                                            <TableCell>
                                                <Chip label={convertUnitFileSize(object.size)} color="warning" variant="outlined" />
                                            </TableCell>
                                            <TableCell>{object.lastModified.toString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default S3StatusRow;
