import { memo } from 'react';
import { Popper, Fade, Box, DialogTitle, DialogContent, Grid, Typography, DialogActions, Button } from 'nxg-ui-wrapper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

import { TextCommon } from '../../common';
import styles from './Confirmation.module.css';

export interface ConfirmationProps {
    title: string;
    content: string;
    open: boolean;
    anchorEl: null | HTMLElement;
    onDisagree: (event?: object, reason?: string) => void;
    onAgree: () => void;
}

const Confirmation = ({ title, content, open, anchorEl, onAgree, onDisagree }: ConfirmationProps): JSX.Element => {
    return (
        <Popper open={open} anchorEl={anchorEl} transition placement="top" disablePortal={false} className={styles.zIndex}>
            {({ TransitionProps }: any): JSX.Element => (
                <Fade {...TransitionProps} timeout={350}>
                    <div>
                        <Box className={styles.box}>
                            <DialogTitle component={'h5'}>{title}</DialogTitle>
                            <DialogContent>
                                <Grid>
                                    <Typography component={'span'}>{content}</Typography>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" variant="contained" startIcon={<CheckCircleIcon />} onClick={onAgree}>
                                    {TextCommon.AGREE}
                                </Button>
                                <Button variant="outlined" color="primary" startIcon={<DoNotDisturbAltIcon />} onClick={onDisagree}>
                                    {TextCommon.DISAGREE}
                                </Button>
                            </DialogActions>
                        </Box>
                    </div>
                </Fade>
            )}
        </Popper>
    );
};

export default memo(Confirmation);
