/* eslint-disable no-var */
import { S3StatusBucket } from '../components/S3StatusGrid/S3StatusRow';
import { FileOfBucket } from '../components/UploadStatusGrid';
import {
    ICompletedScanningType,
    IFetchS3Type,
    INotifyType,
    IThrowErrorUploadType,
    ITriggerErrorType,
    ITriggerLoadingType,
    IUploadType,
    Notify,
} from '../types';
import { AppAction } from '.';
import { AWSS3Config } from '../custom/amplifys3.client';
import { ExtLogicS3 } from '../business';
import { APP_RDS_CONFIG } from '../common/base.common';

interface AuthenticatedState {
    s3Files: S3StatusBucket[];
    equipmentId: string;
    uploadingFiles: FileOfBucket[];
    isScanning: boolean;
    isUploading: boolean;
    isUploaded: boolean;
    isShowPopup: boolean;
    errorReason: string;
    isError: boolean;
    notify: Notify;
    errorMessage: string;
    isShowBackdoor: boolean;
    rdsBucket: AWSS3Config;
    isOtherProcessing: boolean;
}

interface AuthenticatedPayload {
    type: AppAction;
    payload?: any;
}

const initialState: AuthenticatedState = {
    s3Files: [],
    uploadingFiles: [],
    equipmentId: '',
    isScanning: false,
    isUploading: false,
    isUploaded: false,
    isShowPopup: false,
    errorReason: '',
    isError: false,
    notify: new Notify(),
    errorMessage: '',
    isShowBackdoor: false,
    rdsBucket: APP_RDS_CONFIG.AWSS3,
    isOtherProcessing: false,
};

const reducer = (prevState: AuthenticatedState, action: AuthenticatedPayload): AuthenticatedState => {
    switch (action.type) {
        case AppAction.FETCH_S3:
            return {
                ...prevState,
                s3Files: (action.payload as IFetchS3Type).s3Files,
                isOtherProcessing: (action.payload as IFetchS3Type).isOtherProcessing,
            };

        case AppAction.CANCEL_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploaded: false,
                isShowPopup: false,
                uploadingFiles: [],
            };

        case AppAction.CONFIRM_UPLOAD:
            return {
                ...prevState,
                isScanning: true,
                isUploading: true,
                isUploaded: false,
                errorReason: '',
            };

        case AppAction.COMPLETED_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploading: false,
                isUploaded: true,
                errorReason: '',
                notify: {
                    ...prevState.notify,
                    ...(action.payload as INotifyType).notify,
                },
            };

        case AppAction.THROW_ERROR_UPLOAD:
            return {
                ...prevState,
                isScanning: false,
                isUploaded: false,
                uploadingFiles: [...(action.payload as IThrowErrorUploadType).uploadingFiles],
            };

        case AppAction.COMPLETED_FILES_SCANNING:
            return {
                ...prevState,
                equipmentId: (action.payload as ICompletedScanningType).equipmentId,
                uploadingFiles: (action.payload as ICompletedScanningType).uploadingFiles,
                isUploaded: (action.payload as ICompletedScanningType).isUploaded || false,
                isScanning: false,
                isShowPopup: true,
            };

        case AppAction.TRIGGER_ERROR:
            return {
                ...prevState,
                isError: !!(action.payload as ITriggerErrorType).message,
                errorMessage: (action.payload as ITriggerErrorType).message,
                errorReason: (action.payload as ITriggerErrorType).otherMessage || '',
                isScanning: false,
            };

        case AppAction.CLEAR_ERROR:
            return {
                ...prevState,
                isError: false,
            };

        case AppAction.TRIGGER_LOADING:
            return {
                ...prevState,
                isScanning: (action.payload as ITriggerLoadingType).isScanning,
            };

        case AppAction.SET_UPLOAD:
            return {
                ...prevState,
                uploadingFiles: (action.payload as IUploadType).uploadingFiles,
            };

        case AppAction.SET_NOTIFY:
            return {
                ...prevState,
                notify: {
                    ...prevState.notify,
                    ...(action.payload as INotifyType).notify,
                },
            };
        case AppAction.SET_RDS_BUCKET:
            return {
                ...prevState,
                rdsBucket: loadRdsConfiguration(action),
            };
        default:
            throw new Error('Invalid Action!');
    }
};

const loadRdsConfiguration = (action?: AuthenticatedPayload): AWSS3Config => {
    const config = ExtLogicS3.initialConfigToSession(APP_RDS_CONFIG);
    const currentBucketName = config.AWSS3.bucket;
    const currentRegion = config.AWSS3.region;
    const bucketName = action?.payload?.rdsBucket?.bucket?.trim() || currentBucketName;
    const region = action?.payload?.rdsBucket?.region?.trim() || currentRegion;
    const bucket: AWSS3Config = {
        bucket: bucketName,
        region,
    };
    return bucket;
};

export { initialState };
export type { AuthenticatedState, AuthenticatedPayload };
export default reducer;
