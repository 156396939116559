import { AlertColor } from '@mui/material/Alert';
import { FileOfBucket, S3StatusBucket } from '../components';
import { AWSS3Config } from '../custom/amplifys3.client';

export class S3Object {
    eTag!: string;
    key!: string;
    lastModified!: string;
    size!: number;
}

export class FileDb3 extends File {
    path: string | undefined;
}

export class Notify {
    isOpen = false;
    message = '';
    type?: AlertColor = 'success';
}

export interface CustomError {
    message: string;
    otherMessage?: string;
}

export enum BrowserStorageKey {
    S3_BUCKET_RDS = 'aimscs-data-importer-s3-bucket-rds',
    S3_REGION = 'aimscs-data-importer-s3-region',
}

export enum BucketType {
    RDS,
    TIMESTREAM,
    BLOCKING,
}

export enum EnvironmentMode {
    DEV,
    REL,
}

export interface ISetBucket {
    rdsBucket: AWSS3Config;
}

export interface IUploadType {
    uploadingFiles: FileOfBucket[];
}

export interface IThrowErrorUploadType extends IUploadType {}

export interface IFetchS3Type {
    s3Files: S3StatusBucket[];
    isOtherProcessing: boolean;
}

export interface ITriggerLoadingType {
    isScanning: boolean;
}

export interface ICompletedScanningType {
    equipmentId: string;
    uploadingFiles: FileOfBucket[];
    isUploaded?: boolean;
}

export interface ITriggerErrorType {
    message: string;
    otherMessage?: string;
}

export enum ENotifyType {
    ERROR = 'error',
    SUCCESS = 'success',
}

export interface INotifyType {
    notify: INotify;
}

export interface INotify {
    message: string;
    isOpen: boolean;
    type?: ENotifyType;
}
