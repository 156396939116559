import { Heading } from '@aws-amplify/ui-react';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { CardMedia } from 'nxg-ui-wrapper';

import { LOGO } from '../../assets';
import { TextCommon } from '../../common';
import styles from './SignIn.module.css';

const SignIn: DefaultComponents = {
    Header(): JSX.Element {
        return <CardMedia component="img" className={styles.sizing} image={LOGO} />;
    },
    SignIn: {
        Header(): JSX.Element {
            return (
                <Heading className={styles.heading} level={2}>
                    {TextCommon.SIGN_IN_HEADER_TITLE}
                </Heading>
            );
        },
        Footer(): JSX.Element {
            return <></>;
        },
    },
};

export default SignIn;
