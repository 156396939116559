import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress, Container, Grid, Typography } from 'nxg-ui-wrapper';
import format from 'string-format';
import { Auth } from 'aws-amplify';

import { readEquipmentId } from '../../utils';
import {
    CustomError,
    ENotifyType,
    FileDb3,
    ICompletedScanningType,
    IFetchS3Type,
    INotify,
    INotifyType,
    ITriggerErrorType,
    ITriggerLoadingType,
} from '../../types';
import { BaseCommon, TextCommon, MessageCommon, TypeCommon } from '../../common';
import { useAuthenticated } from '../../hooks/context';
import { TypeContext } from '../../store';
import { S3StatusBucket, ErrorNotification, Header, LogPicker, Overlay, S3StatusGrid, Snackbar, UploadingDialog } from '../../components';
import PickerTitle from '../../temp/PickerTitle';
import { ExtLogicS3 } from '../../business';
import styles from './Authenticated.module.css';
import { APP_RDS_CONFIG } from '../../common/base.common';
import s3Client from '../../custom/amplifys3.client';
import useBackdoor from '../../hooks/useBackdoor';

const Authenticated = (): JSX.Element => {
    const [appState, dispatch]: TypeContext = useAuthenticated();
    const {
        s3Files,
        equipmentId,
        uploadingFiles,
        isScanning,
        isUploading,
        isUploaded,
        isShowPopup,
        isError,
        isOtherProcessing,
        notify,
        errorMessage,
        errorReason,
    } = appState;

    // Timer
    const [timer, setTimer] = useState<number>(BaseCommon.APP_INTERVAL_TIME);
    const [auto, setAuto] = useState<boolean>(true);
    const intervalId = useRef<undefined | NodeJS.Timer>();

    const [setOpen] = useBackdoor();

    useEffect(() => {
        const onUnload = (event: any): void => {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.returnValue = 'leave';
        };

        const loadRdsConfigurationBySession = (): void => {
            const config = ExtLogicS3.initialConfigToSession(APP_RDS_CONFIG);
            s3Client.updateConfiguration(config);
        };

        window.addEventListener('beforeunload', onUnload);
        loadRdsConfigurationBySession();
        handleRefresh();

        return () => {
            window.removeEventListener('beforeunload', onUnload);
            clearTimer();
        };
    }, []);

    useEffect(() => {
        if (ExtLogicS3.isCompletedProgress(uploadingFiles)) {
            ExtLogicS3.updateBlockingFlag(false).then(() => notifyWhenUploadCompleted());
        }
    }, [uploadingFiles]);

    useEffect(() => {
        if (timer === 1) {
            handleRefresh();
        }
    }, [timer]);

    // USE_CALLBACK
    const startTimer = useCallback((): void => {
        intervalId.current = setInterval(() => {
            setTimer((prevCount: number) => {
                if (prevCount <= 1) {
                    return BaseCommon.APP_INTERVAL_TIME;
                }
                return prevCount - 1;
            });
        }, 1000);
    }, []);

    const handleCancelUpload = useCallback((event?: object, reason?: string): void => {
        if (reason === 'backdropClick') {
            return;
        }
        dispatch(TypeCommon.setCancelUploadType());
        handleRefresh();
    }, []);

    const handleConfirmUpload = useCallback(async (): Promise<void> => {
        try {
            const isReadyToUpload = await validateBeforeUpload();
            if (!isReadyToUpload) {
                return;
            }

            await ExtLogicS3.updateBlockingFlag(true);
            dispatch(TypeCommon.setConfirmUploadType());
            await ExtLogicS3.calculateUploadingProgress(uploadingFiles, equipmentId, dispatch);
        } catch (err: any) {
            console.error('ERROR::UPLOADING_S3:: ', err);
            handleError({
                message: format(MessageCommon.UPLOADED_THROW_ERROR, err.message),
                otherMessage: err.message,
            });
        }
    }, [uploadingFiles]);

    const validateBeforeUpload = async (): Promise<boolean> => {
        const blocking = await ExtLogicS3.fetchBlockingFromS3();
        const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();

        const isEmptyAllBuckets = (data): boolean => {
            const fileCount = data.reduce((total: number, bucket: S3StatusBucket) => total + bucket.totalSize, 0);
            return fileCount === 0;
        };
        const isReadyToUpload = isEmptyAllBuckets([...s3Data, blocking]);
        if (!isReadyToUpload) {
            const payload: INotifyType = {
                notify: {
                    message: MessageCommon.MESSAGE_ERROR_ALREADY_BLOCKING_FLAG,
                    isOpen: true,
                    type: ENotifyType.ERROR,
                },
            };
            dispatch(TypeCommon.setNotifyType(payload));
        }
        return isReadyToUpload;
    };

    // Function Business
    const clearTimer = (): void => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    };

    const handleChangeAuto = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const checked = event.target.checked;
        if (!checked) {
            setTimer(BaseCommon.APP_INTERVAL_TIME);
            clearTimer();
        } else {
            handleRefresh();
        }
        setAuto(checked);
    };

    const handleError = ({ message, otherMessage }: CustomError): void => {
        const payload: ITriggerErrorType = { message, otherMessage };
        dispatch(TypeCommon.setTriggerErrorType(payload));
    };

    const handleLoadedFiles = async (fs: FileDb3[]): Promise<void> => {
        try {
            const id = await readEquipmentId(fs as FileDb3[]);
            const loadedFiles = ExtLogicS3.buildResourceEntry(fs);
            const payload: ICompletedScanningType = {
                equipmentId: id,
                uploadingFiles: loadedFiles,
            };
            if (ExtLogicS3.isEmptyLocalFiles(loadedFiles)) {
                payload.isUploaded = true;
            }
            setTimeout(() => {
                dispatch(TypeCommon.setCompletedScanningType(payload));
            }, BaseCommon.APP_SCANNING_DELAY_TIME);
        } catch (err: any) {
            console.error('ERROR::LOAD_LOCAL_FILE:: ', err);
            handleError(err);
        }
    };

    const handleScanning = (state: boolean): void => {
        const payload: ITriggerLoadingType = {
            isScanning: state,
        };
        dispatch(TypeCommon.setTriggerLoadingType(payload));
    };

    const handleRefresh = async (): Promise<void> => {
        try {
            const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();
            const s3Blocking = await ExtLogicS3.fetchBlockingFromS3();
            const payload: IFetchS3Type = {
                s3Files: s3Data,
                isOtherProcessing: s3Blocking.totalSize > 0,
            };
            dispatch(TypeCommon.setFetchS3Type(payload));
        } catch (error: any) {
            console.error('ERROR::FETCH_S3:: ', error);
            handleError({
                ...error,
                message: format(MessageCommon.S3_ERROR, error.message),
                otherMessage: error.message,
            });
        } finally {
            clearTimer();
            startTimer();
            setAuto(true);
        }
    };

    const handleCloseNotify = (event?: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        const payload: INotifyType = {
            notify: {
                isOpen: false,
                message: '',
            },
        };
        dispatch(TypeCommon.setNotifyType(payload));
    };

    const notifyWhenUploadCompleted = (): void => {
        let notify: INotify = {
            message: format(MessageCommon.UPLOADED_SUCCESS_ALL_FILES, equipmentId),
            isOpen: true,
            type: ENotifyType.SUCCESS,
        };

        if (ExtLogicS3.isErrorAllFilesUploaded(uploadingFiles)) {
            notify = {
                message: format(MessageCommon.UPLOADED_ERROR_ALL_FILES, equipmentId),
                isOpen: true,
                type: ENotifyType.ERROR,
            };
        } else if (ExtLogicS3.isErrorAnyFileUploaded(uploadingFiles)) {
            notify = {
                message: format(MessageCommon.UPLOADED_SUCCESS_SOME_FILES, equipmentId),
                isOpen: true,
                type: ENotifyType.SUCCESS,
            };
        }
        const payload: INotifyType = { notify };
        dispatch(TypeCommon.setCompletedUploadType(payload));
        handleRefresh();
    };

    const handleClickRefresh = async (): Promise<void> => {
        try {
            const s3Data = await ExtLogicS3.fetchEquipmentLogFromS3();
            const s3Blocking = await ExtLogicS3.fetchBlockingFromS3();
            const payload: IFetchS3Type = {
                s3Files: s3Data,
                isOtherProcessing: s3Blocking.totalSize > 0,
            };
            dispatch(TypeCommon.setFetchS3Type(payload));
        } catch (error: any) {
            console.error('ERROR::CLICK_FETCH_S3:: ', error);
            handleError({
                ...error,
                message: format(MessageCommon.S3_ERROR, error.message),
                otherMessage: error.message,
            });
        }
    };

    const handleSignOut = (): void => {
        Auth.signOut();
    };

    const handleClose = (): void => {
        dispatch(TypeCommon.setClearErrorType());
    };

    const handleOpenBackdoor = async (): Promise<void> => {
        await setOpen(() => {
            handleRefresh();
        });
    };

    const handleForceRemove = async (): Promise<void> => {
        await ExtLogicS3.updateBlockingFlag(false);
        await handleRefresh();
    };

    // calculate the conditions USE_MEMO
    const isBackendProcessing = useMemo((): boolean => {
        if (s3Files.length > 0) {
            return s3Files[0].objects.length > 0 || s3Files[1].objects.length > 0;
        }
        return false;
    }, [s3Files]);

    return (
        <>
            <Header onSignOut={handleSignOut} onOpenBackdoor={handleOpenBackdoor} onForceRemoveBlocking={handleForceRemove} />
            <Container maxWidth={false} className={styles.container}>
                <Grid container maxWidth="xl" rowSpacing={1} direction="column" className={styles.wrapper}>
                    <Grid item className={styles.item}>
                        <Typography variant="h4" component="h4" className={styles.title}>
                            {TextCommon.APP_TITLE}
                        </Typography>
                    </Grid>
                    <Grid item className={styles.item}>
                        <LogPicker
                            disabled={isOtherProcessing || isBackendProcessing || isUploading}
                            filterFolder={[...BaseCommon.APP_RDS_FOLDER, ...BaseCommon.APP_TIMESTREAM_FOLDER, '']}
                            filterFileExtensions={BaseCommon.APP_ACCEPT_EXTENSION_FILES}
                            onLoading={handleScanning}
                            onError={handleError}
                            onLoadedFiles={handleLoadedFiles}
                        >
                            <PickerTitle />
                        </LogPicker>
                    </Grid>

                    <Grid item className={`${styles.table} ${styles.item}`}>
                        <S3StatusGrid
                            columns={[TextCommon.NO, TextCommon.BUCKET, TextCommon.TOTAL_FILE, TextCommon.TOTAL_SIZE]}
                            nestedColumns={[TextCommon.EQUIPMENT, TextCommon.FILENAME, TextCommon.SIZE, TextCommon.DATE]}
                            data={s3Files}
                            auto={auto}
                            timer={timer}
                            onRefresh={handleClickRefresh}
                            onChangeAuto={handleChangeAuto}
                        />
                    </Grid>
                </Grid>
            </Container>
            <ErrorNotification open={isError} message={errorMessage} onClose={handleClose} />
            <Overlay open={isScanning} content={TextCommon.SCANNING} element={<CircularProgress color="inherit" />} />
            {isShowPopup && (
                <UploadingDialog
                    open={isShowPopup}
                    data={uploadingFiles}
                    isUploading={isUploading}
                    isUploaded={isUploaded}
                    errorReason={errorReason}
                    title={equipmentId}
                    onClose={handleCancelUpload}
                    onConfirm={handleConfirmUpload}
                />
            )}
            <Snackbar open={notify.isOpen} message={notify.message} type={notify.type} onClose={handleCloseNotify} />
        </>
    );
};

export default memo(Authenticated);
