import { SyntheticEvent } from 'react';
import { Alert, AlertColor } from '@mui/material';
import * as MuiSnackbar from '@mui/material/Snackbar';

import globalStyles from '../../global/style.module.css';
import { anchorOriginTopCenter, autoHideDuration } from '../../global/style.object';

type SnackbarProps = MuiSnackbar.SnackbarProps & {
    message: string | JSX.Element;
    type: AlertColor | undefined;
    onClose: ((event: Event | SyntheticEvent<any, Event>, reason?: MuiSnackbar.SnackbarCloseReason) => void) | undefined;
};

const Snackbar = (props: SnackbarProps): JSX.Element => {
    const { open, type, message, onClose } = props;

    if (!open) {
        return <></>;
    }
    return (
        <MuiSnackbar.default
            anchorOrigin={anchorOriginTopCenter}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            disableWindowBlurListener={true}
        >
            <Alert variant="filled" onClose={onClose} severity={type} className={globalStyles.w100}>
                {message}
            </Alert>
        </MuiSnackbar.default>
    );
};

export default Snackbar;
