import { BucketType, ISetBucket } from '../types';
import { TextCommon, TypeCommon } from '../common';
import { useAuthenticated } from './context';
import { TypeContext } from '../store';
import { ExtLogicS3 } from '../business';
import { AmplifyS3Client, AmplifyS3Config, AWSS3Config } from '../custom/amplifys3.client';
import s3Client from '../custom/amplifys3.client';

const useBackdoor = (): any[] => {
    const [appState, dispatch]: TypeContext = useAuthenticated();

    const setOpen = async (callback: () => void): Promise<void> => {
        const inputUrl = window.prompt(TextCommon.INPUT_BUCKET_URL);

        const isCancelPopup = inputUrl === null;
        if (isCancelPopup) {
            return;
        }

        const s3Config = await handleBucketUrl(inputUrl);
        if (s3Config === null) {
            return;
        }

        syncUpConfiguration(s3Config, callback);
    };

    const syncUpConfiguration = async (s3Config: AWSS3Config, onRefreshParent: () => void): Promise<void> => {
        const payload: ISetBucket = {
            rdsBucket: s3Config,
        };
        const newConfiguration: AmplifyS3Config<BucketType> = {
            name: BucketType.RDS,
            AWSS3: s3Config,
        };

        ExtLogicS3.storeConfigToSession(newConfiguration.name, newConfiguration.AWSS3.bucket, newConfiguration.AWSS3.region);
        s3Client.updateConfiguration(newConfiguration);
        dispatch(TypeCommon.setRdsBucketType(payload));
        onRefreshParent();
    };

    const handleBucketUrl = async (url: string): Promise<AWSS3Config | null> => {
        const urlCleaned = url.trim();
        const s3Config = ExtLogicS3.getBucketAndRegion(urlCleaned);

        // validate the Bucket
        const result = await AmplifyS3Client.validateBucket(s3Config);

        if (result.valid) {
            return s3Config;
        }

        window.confirm(result.errorMessage);
        return null;
    };

    return [setOpen];
};

export default useBackdoor;
