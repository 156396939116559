/* eslint-disable @typescript-eslint/ban-types */
import * as MuiButton from '@mui/material/Button';

import globalStyles from '../../global/style.module.css';

interface ButtonProps extends MuiButton.ButtonProps {
    disabled?: boolean;
}

const Button = (props: ButtonProps): JSX.Element => {
    const { disabled } = props;

    return (
        <MuiButton.default
            style={{ textTransform: 'none', fontWeight: 'bold', ...props.style }}
            className={globalStyles[disabled ? 'cursor-no-drop' : 'cursor-pointer']}
            {...props}
        >
            {props.children}
        </MuiButton.default>
    );
};

export default Button;
