import { Fragment, memo, useState } from 'react';
import { styled } from '@mui/material';
import { TableRow, TableCell, IconButton, Chip, Collapse, Box, Typography, Table, TableHead, TableBody, Tooltip } from 'nxg-ui-wrapper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { FileOfBucket, UploadObjectFile } from '.';
import { convertUnitFileSize } from '../../utils';
import LinearProgress from '../LinearProgress';
import globalStyles from '../../global/style.module.css';
import { BaseCommon } from '../../common';

const CustomizedCell = styled(TableCell)(
    ({ theme }) => `
    color: ${theme.palette.primary.main};
    fontWeight: ${800};
`,
);

export interface UploadStatusRowProps {
    columns: string[];
    bucket: FileOfBucket;
    header: string;
    errorReason: string;
}

const UploadStatusRow = ({ bucket, header, columns, errorReason }: UploadStatusRowProps): JSX.Element => {
    const [open, setOpen] = useState(true);
    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, bgcolor: theme => theme.palette.grey[200] }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={(): void => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <CustomizedCell component="th" scope="row">
                    <Chip label={bucket.name} color="primary" variant="outlined" />
                </CustomizedCell>
                <CustomizedCell>
                    <Chip label={bucket.totalFile} color="success" />
                </CustomizedCell>
                <CustomizedCell>
                    <Chip label={'~ ' + convertUnitFileSize(bucket.totalSize)} color="error" variant="filled" />
                </CustomizedCell>
                <TableCell>
                    <LinearProgress color="error" progress={bucket.progress} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography gutterBottom component="div">
                                {header}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead sx={{ background: theme => theme.palette.info.main }}>
                                    <TableRow>
                                        {columns.map((item: string, index: number) => (
                                            <TableCell className={`${globalStyles.white} ${globalStyles.f600}`} key={index}>
                                                {item}{' '}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bucket.objects.map((object: UploadObjectFile, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>{object.filename}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {object.folder}
                                            </TableCell>
                                            <TableCell>
                                                <Chip label={convertUnitFileSize(object.size)} color="warning" variant="outlined" />
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {!object.isError ? (
                                                    object.progress !== BaseCommon.PERCENT_COMPLETED ? (
                                                        <LinearProgress progress={object.progress} skip={false} />
                                                    ) : (
                                                        <TaskAltIcon color="success" />
                                                    )
                                                ) : (
                                                    <Tooltip title={errorReason} arrow placement="top">
                                                        <ErrorIcon color="error" />
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default memo(UploadStatusRow);
