import { DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Dialog, Button } from 'nxg-ui-wrapper';
import { TransitionProps } from '@mui/material/transitions';
import ErrorIcon from '@mui/icons-material/Error';

import { TextCommon } from '../../common';
import styles from './ErrorNotification.module.css';

export interface ErrorNotificationProps {
    open: boolean;
    message: string;
    transition?: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>;
    onClose: () => void;
}

const ErrorNotification = ({ open, message, onClose, transition }: ErrorNotificationProps): JSX.Element => {
    if (!open) {
        return <></>;
    }
    return (
        <Dialog open={open} onClose={onClose} TransitionComponent={transition}>
            <DialogTitle className={styles.title}>{TextCommon.ALERT}</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.content}>
                    <ErrorIcon />{' '}
                    <Typography marginLeft={1} component="span">
                        {message}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.action}>
                <Button onClick={onClose}>{TextCommon.CLOSE}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorNotification;
