enum Action {
    FETCH_S3 = 'FETCH_S3',
    COMPLETED_FILES_SCANNING = 'COMPLETED_FILES_SCANNING',
    CLEAR_ERROR = 'CLEAR_ERROR',
    TRIGGER_ERROR = 'TRIGGER_ERROR',
    TRIGGER_LOADING = 'TRIGGER_LOADING',
    SET_NOTIFY = 'SET_NOTIFY',

    CANCEL_UPLOAD = 'CANCEL_UPLOAD',
    CONFIRM_UPLOAD = 'CONFIRM_UPLOAD',
    SET_UPLOAD = 'SET_UPLOAD',
    COMPLETED_UPLOAD = 'COMPLETED_UPLOAD',
    THROW_ERROR_UPLOAD = 'THROW_ERROR_UPLOAD',

    SET_RDS_BUCKET = 'SET_RDS_BUCKET',
}

export default Action;
