import { memo } from 'react';
import { styled, tableCellClasses } from '@mui/material';
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from 'nxg-ui-wrapper';

import NoData from '../NoData';
import { FileDb3 } from '../../types';
import { TextCommon } from '../../common';
import UploadStatusRow from './UploadStatusRow';
import globalStyles from '../../global/style.module.css';

export interface UploadObjectFile {
    filename: string;
    folder: string;
    lastModified: number;
    size: number;
    progress: number;
    file: FileDb3 | null;
    indexS3: number;
    isError: boolean;
}

export interface FileOfBucket {
    name: string | undefined;
    totalFile: number;
    totalSize: number;
    progress: number;
    objects: UploadObjectFile[];
    isError: boolean;
}
export interface UploadStatusGridProps {
    columns: string[];
    nestedColumns: string[];
    data: FileOfBucket[];
    errorReason: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const UploadStatusGrid = ({ columns, nestedColumns, data, errorReason }: UploadStatusGridProps): JSX.Element => {
    return (
        <Grid container direction="column">
            <Grid paddingY={2}>
                <Typography className={globalStyles.f500}>{TextCommon.UPLOADING_TABLE_TITLE}</Typography>
            </Grid>
            <Divider variant="fullWidth" />
            <Grid paddingY={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((item: any, index: number) => (
                                    <StyledTableCell key={index}>{item} </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data.length === 0 || data.every((file: FileOfBucket) => file.objects.length === 0)) && (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            )}
                            {(data[0]?.objects.length !== 0 || data[1]?.objects.length !== 0) &&
                                data
                                    .filter((item: FileOfBucket) => item.objects.length)
                                    .map((item: FileOfBucket, index: number) => (
                                        <UploadStatusRow
                                            key={index}
                                            columns={nestedColumns}
                                            bucket={item}
                                            header={TextCommon.UPLOADING_TABLE_SUB_TITLE}
                                            errorReason={errorReason}
                                        />
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default memo(UploadStatusGrid);
export * from './UploadStatusRow';
