import { ThemeOptions, SnackbarOrigin } from '@mui/material';

import { BaseCommon } from '../common';

export const themeRoot: ThemeOptions = {
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: '0 !important',
                    margin: '0 !important',
                },
            },
        },
    },
};
export const anchorOriginTopCenter: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };
export const autoHideDuration = BaseCommon.APP_INTERVAL_TIME * 0.7 * 1000;
